.cell_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.cell_container, p {
    min-width: 50px;
}

.progress_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    color: #565555;
}