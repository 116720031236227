.main-layout {
    display: flex;
    flex-direction: column;
    height: 100vh;

    .container {
        flex-grow: 1;
        overflow: auto;
    }

    &.desktop {
        flex-direction: row;
    }
}