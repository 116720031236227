.map-container {
    flex-grow: 1;
}

.sidebar-container {
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.20),
        0px 3px 4px 0px rgba(0, 0, 0, 0.14),
        0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

#popup {
    position: relative;

    .popup-content {
        position: absolute;
        left: -150px;
        top: -30px;
        transform: translateY(-100%);
        width: 300px;
    }
}