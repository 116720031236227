.activity-type {
    font-size: 1em;
    border-radius: 5px;
    padding: 5px 7px;
    text-transform: capitalize;
    width: 75px;
    text-align: center;
}
.filter-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;

    & > div {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
    }
}

.red-type {
    background: #f4433621;
    border: 1px solid #f44336;
    color: #f44336;
}

.green-type {
    background: #4caf501a;
    border: 1px solid #4caf50;
    color: #4caf50;
}

.blue-type {
    background: #2196f324;
    border: 1px solid #2196f3;
    color: #2196f3;
}

.yellow-type {
    background: #ffc1071a;
    border: 1px solid #ffc107;
    color: #ffc107;
}

.filter-select {
    min-width: 200px;
}
.filter-search {
    min-width: 300px !important
}
.filter-search-button {
    margin: 0 20px !important;
}
.filter-label {
   margin-right: 10px !important;
}