@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
.pac-container{z-index:1500 !important}


.main-layout{display:flex;flex-direction:column;height:100vh}.main-layout .container{flex-grow:1;overflow:auto}.main-layout.desktop{flex-direction:row}

.map-container{flex-grow:1}.sidebar-container{box-shadow:0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)}#popup{position:relative}#popup .popup-content{position:absolute;left:-150px;top:-30px;-webkit-transform:translateY(-100%);transform:translateY(-100%);width:300px}

.MuiButtonBase-root.MuiBottomNavigationAction-root{min-width:0px}

.red{color:#f14444}.green{color:#60e060}.yellow{color:#f1e148}

.cell_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.cell_container, p {
    min-width: 50px;
}

.progress_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    color: #565555;
}
.activity-type {
    font-size: 1em;
    border-radius: 5px;
    padding: 5px 7px;
    text-transform: capitalize;
    width: 75px;
    text-align: center;
}
.filter-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;

    & > div {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
    }
}

.red-type {
    background: #f4433621;
    border: 1px solid #f44336;
    color: #f44336;
}

.green-type {
    background: #4caf501a;
    border: 1px solid #4caf50;
    color: #4caf50;
}

.blue-type {
    background: #2196f324;
    border: 1px solid #2196f3;
    color: #2196f3;
}

.yellow-type {
    background: #ffc1071a;
    border: 1px solid #ffc107;
    color: #ffc107;
}

.filter-select {
    min-width: 200px;
}
.filter-search {
    min-width: 300px !important
}
.filter-search-button {
    margin: 0 20px !important;
}
.filter-label {
   margin-right: 10px !important;
}
