.red {
    color: rgb(241, 68, 68);
}

.green {
    color: rgb(96, 224, 96);
}

.yellow {
    color: rgb(241, 225, 72);
}